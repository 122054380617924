import type {RemoveIndex} from 'common/types'
import type {ValidationErrors} from 'final-form'
import arrayMutators from 'final-form-arrays'
import {isFunction} from 'lodash-es'
import type {ComponentType, ReactElement, ReactNode} from 'react'
import getDisplayName from 'react-display-name'
import type {FormProps, FormRenderProps} from 'react-final-form'
import {Form} from 'react-final-form'
import type {TypeOf, ZodType} from 'zod'
import {validator} from './forms'


declare module 'react-final-form' {
  interface FormRenderProps {
    submitError?: unknown
  }
}

const identity = (values: Record<string, unknown>) => values

type Config<
  TSchema extends ZodType<Record<string, unknown>>,
> = {
  schema: TSchema
  parse?: (values: TypeOf<TSchema>) => TypeOf<TSchema>
  format?: (values: Partial<TypeOf<TSchema>>) => Partial<TypeOf<TSchema>>
  validate?: (values: unknown) => {values: TypeOf<TSchema>, errors: undefined}
    | {values: undefined, errors: ValidationErrors}
}

type WrappedFormProps<TValues extends Record<string, unknown>, TProps extends Record<string, unknown>> = RemoveIndex<Omit<FormRenderProps<TValues>, 'form'>> & TProps

type FinalProps<
  TSchema extends ZodType<Record<string, unknown>>,
  TInitialValues extends Partial<TypeOf<TSchema>> & Record<string, unknown>,
  TRestProps extends Record<string, unknown>
> = {
  children?: ((props: WrappedFormProps<TypeOf<TSchema>, TRestProps> & {form: ReactNode}) => ReactElement)
  initialValues?: TInitialValues
  innerProps?: TRestProps
} & Omit<RemoveIndex<FormProps<TypeOf<TSchema>>>, 'children'>

const withForm = <
  TSchema extends ZodType<Record<string, unknown>>,
  TInitialValues extends Partial<TypeOf<TSchema>> & Record<string, unknown>,
>({
    schema,
    parse: providedParse,
    format: providedFormat,
    validate: providedValidate,
  }: Config<TSchema>) => <TProps extends Record<string, unknown>
>(WrappedComponent: ComponentType<WrappedFormProps<TypeOf<TSchema>, TProps>>) => {
    const validate = providedValidate || validator(schema)
    const parse = providedParse || identity
    const format = providedFormat || identity
    const Final = ({
      onSubmit, children, initialValues, innerProps = {} as TProps, ...rest
    }: FinalProps<TSchema, TInitialValues, TProps>) => {
      return (
        <Form<TypeOf<TSchema>>
            mutators={{
              ...arrayMutators,
            }}
            keepDirtyOnReinitialize
            onSubmit={
              // Because onSubmit will only ever be called after successful validation
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              (values, ...args) => onSubmit(validate(parse(values)).values!, ...args)
            }
            validate={(values) => validate(parse(values)).errors}
            initialValues={initialValues ? format(initialValues) : {}}
            {...rest}
        >
          {({handleSubmit, initialValues, ...formProps}) => {
            const form = (
              <form onSubmit={handleSubmit}>
                <WrappedComponent
                    handleSubmit={handleSubmit}
                    initialValues={initialValues}
                    {...formProps}
                    {...innerProps}
                />
              </form>
            )

            if (isFunction(children)) {
              return children({handleSubmit, initialValues, ...innerProps, ...formProps, form})
            }
            return form
          }}
        </Form>
      )
    }

    Final.displayName = `FinalForm(${getDisplayName(WrappedComponent)})`
    return Final
  }

export default withForm
