export const ERA = 'ERA'
export const SLOVAKIA = 'slovakia'
export const CZECH_REPUBLIC = 'czech-republic'
export const POLAND = 'poland'
export const AUSTRIA = 'austria'
export const GERMANY = 'germany'

export const ALL_CARRIER_NSA_TYPES = [
  ERA,
  SLOVAKIA,
  CZECH_REPUBLIC,
  POLAND,
  AUSTRIA,
  GERMANY,
] as const

export type CarrierNSAType = typeof ALL_CARRIER_NSA_TYPES[number]
