export const REASON1 = 'reason1'
export const REASON2 = 'reason2'
export const REASON3 = 'reason3'
export const REASON4 = 'reason4'
export const REASON5 = 'reason5'
export const REASON6 = 'reason6'
export const REASON7 = 'reason7'

export const ALL_LICENSE_CANCELLATION_REASONS = [
  REASON1,
  REASON2,
  REASON3,
  REASON4,
  REASON5,
  REASON6,
  REASON7,
] as const

export type LicenseCancellationReason = typeof ALL_LICENSE_CANCELLATION_REASONS[number]
