export const YES = 'yes'
export const NO = 'no'
export const PARTIALLY = 'partially'

export const ALL_CLOSED_AREAL_TYPES = [
  YES,
  NO,
  PARTIALLY,
] as const

export type ClosedArealType = typeof ALL_CLOSED_AREAL_TYPES[number]
