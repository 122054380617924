export const PERSONAL = 'personal'
export const CARGO = 'cargo'
export const PERSONAL_PLUS_CARGO = 'personal-plus-cargo'

export const ALL_TRANSPORT_TYPES = [
  PERSONAL,
  CARGO,
  PERSONAL_PLUS_CARGO,
] as const

export type TransportType = typeof ALL_TRANSPORT_TYPES[number]
