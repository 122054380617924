export const SIDING = 'siding'
export const LOCAL = 'local'
export const TEST = 'test'
export const NATIONAL = 'national'
export const REGIONAL = 'regional'

export const ALL_PERMIT_TYPES = [
  SIDING,
  LOCAL,
  TEST,
  NATIONAL,
  REGIONAL,
] as const

export type PermitType = typeof ALL_PERMIT_TYPES[number]
