export const ELECTRONICS = 'electronics'
export const PRESSURES = 'pressures'
export const LIFTS = 'lifts'
export const CABLES = 'cables'
export const GASES = 'gases'

export const ALL_UTZ_TYPES = [
  ELECTRONICS,
  PRESSURES,
  LIFTS,
  CABLES,
  GASES,
] as const

export type UtzType = typeof ALL_UTZ_TYPES[number]
