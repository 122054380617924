import {Button, Stack} from '@mui/material'
import {If} from 'babel-plugin-jsx-control-statements'
import * as schemas from 'common/schemas'
import {isString} from 'lodash-es'
import CheckboxInput from '../../form/CheckboxInput'
import PasswordInput from '../../form/PasswordInput'
import TextInput from '../../form/TextInput'
import {showErrorOnBlur} from '../../form/forms'
import withForm from '../../form/withForm'


const schema = schemas.auth.login.required({email: true, password: true})

export default withForm({schema})(({
  submitting, submitError,
}) => (
  <>
    <Stack useFlexGap gap={2}>
      <TextInput
          name="email"
          label="E-mailová adresa"
          required
          enableAutofill
          showError={showErrorOnBlur}
      />
      <PasswordInput
          name="password"
          label="Heslo"
          required
      />
      <CheckboxInput label="Zůstat přihlášen" name="remember" />
      <Button variant="contained" type="submit" disabled={submitting}>Přihlásit</Button>
      <If condition={isString(submitError)}>
        <span>{submitError as string}</span>
      </If>
    </Stack>
  </>
))
