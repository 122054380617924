export const BELGIUM = 'belgium'
export const BULGARIA = 'bulgaria'
export const CZECH_REPUBLIC = 'czech-republic'
export const DENMARK = 'denmark'
export const ESTONIA = 'estonia'
export const FINLAND = 'finland'
export const FRANCE = 'france'
export const CHANNEL_TUNNEL = 'channel-tunnel'
export const CROATIA = 'croatia'
export const IRELAND = 'ireland'
export const ITALY = 'italy'
export const LITHUANIA = 'lithuania'
export const LATVIA = 'latvia'
export const LUXEMBOURG = 'luxembourg'
export const HUNGARY = 'hungary'
export const GERMANY = 'germany'
export const NETHERLANDS = 'netherlands'
export const NORWAY = 'norway'
export const POLAND = 'poland'
export const PORTUGAL = 'portugal'
export const AUSTRIA = 'austria'
export const ROMANIA = 'romania'
export const GREECE = 'greece'
export const SLOVAKIA = 'slovakia'
export const SLOVENIA = 'slovenia'
export const SPAIN = 'spain'
export const SWEDEN = 'sweden'
export const SWITZERLAND = 'switzerland'

export const ALL_LICENSE_NSA_TYPES = [
  BELGIUM,
  BULGARIA,
  CZECH_REPUBLIC,
  DENMARK,
  ESTONIA,
  FINLAND,
  FRANCE,
  CHANNEL_TUNNEL,
  CROATIA,
  IRELAND,
  ITALY,
  LITHUANIA,
  LATVIA,
  LUXEMBOURG,
  HUNGARY,
  GERMANY,
  NETHERLANDS,
  NORWAY,
  POLAND,
  PORTUGAL,
  AUSTRIA,
  ROMANIA,
  GREECE,
  SLOVAKIA,
  SLOVENIA,
  SPAIN,
  SWEDEN,
  SWITZERLAND,
] as const

export type LicenseNSAType = typeof ALL_LICENSE_NSA_TYPES[number]
