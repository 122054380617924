import {GlobalStyles as MuiGlobalStyles} from '@mui/material'


const globalStyles = {
  ':any-link': {
    textDecoration: 'none',
  },
}

const GlobalStyles = () => {
  return (
    // Rewrite browser global styles */
    // eslint-disable-next-line react/forbid-component-props
    <MuiGlobalStyles styles={globalStyles} />
  )
}

export default GlobalStyles
