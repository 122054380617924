const downloadFile = (object: Blob, filename: string) => {
  const url = window.URL.createObjectURL(object)
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download = filename
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url)
  a.parentNode?.removeChild(a)
}

export default downloadFile
