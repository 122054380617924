export const PUBLIC = 'public'
export const PRIVATE = 'private'
export const PUBLIC_PLUS_PRIVATE = 'public-plus-private'

export const ALL_TRANSPORT_CATEGORIES = [
  PUBLIC,
  PRIVATE,
  PUBLIC_PLUS_PRIVATE,
] as const

export type TransportCategory = typeof ALL_TRANSPORT_CATEGORIES[number]
