import {Menu, IconButton} from '@mui/material'
import {styled} from '@mui/material/styles'
import {If} from 'babel-plugin-jsx-control-statements'
import {isEmpty} from 'lodash-es'
import {useState} from 'react'
import type {FC, MouseEvent, ComponentProps, ReactNode} from 'react'


const StyledIconButton = styled(IconButton)(({theme}) => ({
  margin: theme.spacing(-1),
  marginLeft: theme.spacing(0.5),

  [theme.breakpoints.down('sm')]: {
    padding: `0 ${theme.spacing(0.5)} 0`,
  },
}))

type ButtonMenuProps = ComponentProps<typeof IconButton> & {
  icon?: ReactNode,
  onClick?: (e: MouseEvent<HTMLElement>) => void,
}

const ButtonMenu: FC<ButtonMenuProps> = ({icon, onClick, children}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleMenu = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)

    if (onClick) {
      onClick(e)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <If condition={!isEmpty(icon)}>
      <StyledIconButton onClick={handleMenu}>
        {icon}
      </StyledIconButton>
      <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
      >
        {children}
      </Menu>
    </If>
  )
}

export default ButtonMenu
