export const NATIONAL_PLUS_REGIONAL = 'national-plus-regional'
export const BORDER_CROSSING = 'border-crossing'
export const TRACKS = 'tracks'

export const ALL_ACTIVITY_TYPES = [
  NATIONAL_PLUS_REGIONAL,
  BORDER_CROSSING,
  TRACKS,
] as const

export type ActivityType = typeof ALL_ACTIVITY_TYPES[number]
