const parseContentDisposition = (header: string) => {
  const parts = header.split(';').map((part) => part.trim())
  const kind = parts.find((part) => part.indexOf('=') === -1)
  const filenames = parts
    .filter((part) => part.startsWith('filename'))
    .map((part) => {
      const [key, value] = part.split('=')
      if (!value) return undefined
      if (key === 'filename') {
        if (value[0] === '"') return value.slice(1, -1)
        return value
      } else if (key === 'filename*') {
        return decodeURIComponent(value).split('\'\'')[1]
      }
      return undefined
    })
    .filter(Boolean)

  if (filenames.length === 0) return {type: kind, filename: undefined}
  return {type: kind, filename: filenames.at(-1)}
}

export default parseContentDisposition
