export const BUILDING_JOIN_PERMIT = 'building-join-permit'
export const TRIAL_OPERATION = 'trial-operation'
export const APPROVAL = 'approval'
export const REMOVE_PERMIT = 'remove-permit'

export const ALL_INFRASTRUCTURE_DOCUMENT_TYPES = [
  BUILDING_JOIN_PERMIT,
  TRIAL_OPERATION,
  APPROVAL,
  REMOVE_PERMIT,
] as const

export type InfrastructureDocumentType = typeof ALL_INFRASTRUCTURE_DOCUMENT_TYPES[number]
