import type {PaletteOptions} from '@mui/material'


export default {
  common: {
    black: 'black',
    white: 'white',
  },
  primary: {
    light: '#5A529C',
    main: '#312783',
    dark: '#282160',
    contrastText: '#FFFFFF',
  },
  secondary: {
    light: '#E9E8F2',
    main: '#D3D0EA',
    contrastText: '#312783',
  },
  success: {
    light: '#F0FAF7',
    main: '#10B981',
    contrastText: '#10B981',
  },
  info: {
    dark: '#686868',
    main: '#9891CE',
    light: '#E9E7F4',
  },
  warning: {
    main: '#F79009',
  },
  error: {
    light: '#FDF0F0',
    main: '#DC0D15',
    contrastText: '#FFFFFF',
  },
  neutral: {
    main: '#686868',
  },
  text: {
    primary: '#3B3B3B',
    secondary: '#A8A8A8',
  },
  background: {
    default: '#F9F9F9',
    paper: 'white',
  },
  grey: {
    300: '#F3F4F5',
    400: '#D2D2D2',
    600: '#686868',
  },
  tonalOffset: 0.4,
  divider: '#E9E8F2',
} satisfies PaletteOptions
