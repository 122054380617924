export const ELECTRIC_RAILWAY_DISTRIBUTIONS = 'electric-railway-distributions'
export const ELECTRIC_RAILWAY_STATIONS = 'electric-railway-stations'
export const TRACTION_STATIONS = 'traction-stations'
export const TRACTION_LINES = 'traction-lines'
export const ELECTRICAL_DEVICES_FROM_TRACTION_LINES = 'electrical-devices-from-traction-lines'
export const ELECTRICAL_RAILWAY_VEHICLE_DEVICES = 'electrical-railway-vehicle-devices'
export const HIGH_CURRENT_DEVICES = 'high-current-devices'
export const CABLE_CAR_ELECTRICAL_DEVICES = 'cable-car-electrical-devices'
export const BACKUP_POWER_SOURCES = 'backup-power-sources'
export const TEST_ELECTRONIC_DEVICE = 'test-electronic-device'
export const SECURITY_DEVICES = 'security-devices'
export const PROTECTION_AGAINST_ATMOSPHERIC_AND_STATIC_ELECTRICITY = 'protection-against-atmospheric-and-static-electricity'
export const PROTECTION_AGAINST_NEGATIVE_EFFECTS_OF_BACK_TRACTION = 'protection-against-negative-effects-of-back-traction'
export const ELECTRIC_RAILWAY_NETWORKS_AND_ELECTRIC_RAILWAY_WIRING = 'electric-railway-networks-and-electric-railway-wiring'

export const ALL_ELECTRONIC_DEVICE_TYPES = [
  ELECTRIC_RAILWAY_DISTRIBUTIONS,
  ELECTRIC_RAILWAY_STATIONS,
  TRACTION_STATIONS,
  TRACTION_LINES,
  ELECTRICAL_DEVICES_FROM_TRACTION_LINES,
  ELECTRICAL_RAILWAY_VEHICLE_DEVICES,
  HIGH_CURRENT_DEVICES,
  CABLE_CAR_ELECTRICAL_DEVICES,
  BACKUP_POWER_SOURCES,
  TEST_ELECTRONIC_DEVICE,
  SECURITY_DEVICES,
  PROTECTION_AGAINST_ATMOSPHERIC_AND_STATIC_ELECTRICITY,
  PROTECTION_AGAINST_NEGATIVE_EFFECTS_OF_BACK_TRACTION,
  ELECTRIC_RAILWAY_NETWORKS_AND_ELECTRIC_RAILWAY_WIRING,
] as const

export type ElectronicDeviceType = typeof ALL_ELECTRONIC_DEVICE_TYPES[number]
