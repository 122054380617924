import {CssBaseline} from '@mui/material'
import {ThemeProvider as MuiThemeProvider} from '@mui/material/styles'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import cs from 'date-fns/locale/cs'
import type {FC, ReactNode} from 'react'
import GlobalStyles from './GlobalStyles'
import theme from './theme'


type ThemeProviderProps = {
  children: ReactNode
}

const ThemeProvider: FC<ThemeProviderProps> = ({children}) => (
  <MuiThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={cs}>
      <GlobalStyles />
      <CssBaseline />
      {children}
    </LocalizationProvider>
  </MuiThemeProvider>
)

export default ThemeProvider
