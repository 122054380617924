import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {IconButton, InputAdornment} from '@mui/material'
import type {ComponentProps, FC} from 'react'
import {useState} from 'react'
import type {UseFieldConfig} from 'react-final-form'
import {useField} from 'react-final-form'
import {TextInput} from '../visual'
import type {ShowError} from './forms'
import {showErrorOnChange} from './forms'


type PasswordInputProps = ComponentProps<typeof TextInput> & {
  name: string,
  readOnly?: false,
  showError?: ShowError,
  fieldProps?: UseFieldConfig<string, string>,
}

const PasswordInput: FC<PasswordInputProps> = ({
  readOnly, name, label, required, helperText, fullWidth = true, showError = showErrorOnChange, fieldProps, ...props
}) => {
  const {input: {value, onChange, ...restInput}, meta} = useField<string>(name, fieldProps)
  const {isError, helperTextOrError} = showError({meta, helperText})
  const [showPassword, setShowPassword] = useState(false)

  const handleShowPassword = () => setShowPassword((prevState) => !prevState)

  const Icon = showPassword ? Visibility : VisibilityOff

  if (readOnly) return null // Passwords can only be changed, not read
  return (
    <TextInput
        fullWidth={fullWidth}
        label={label}
        helperText={helperTextOrError}
        error={isError}
        onChange={onChange}
        name={name}
        value={value}
        type={showPassword ? 'text' : 'password'}
        required={required}
        inputProps={{required, ...restInput}}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleShowPassword}>
                <Icon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...props}
    />
  )
}

export default PasswordInput
