import * as Sentry from '@sentry/react'
import {useEffect} from 'react'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import config from '../config'


if (config.sentry) {
  Sentry.init({
    dsn: config.sentry,
    release: `vlecky@${config.buildId}`,
    environment: 'staging',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
      }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', 'olup-api-dev.workey.cz', 'olup-api-dev.onrender.com'],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  })
}

export const createSentryBrowserRouter = config.sentry
  ? Sentry.wrapCreateBrowserRouter(createBrowserRouter)
  : createBrowserRouter

export default Sentry
