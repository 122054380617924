export const SIDING = 'siding'
export const LOCAL = 'local'
export const NATIONWIDE_PLUS_REGIONAL = 'nationwide-plus-regional'
export const NATIONWIDE = 'nationwide'
export const REGIONAL = 'regional'
export const SIDING_PLUS_PERIMETER = 'siding-plus-perimeter'
export const STATION = 'station'

export const ALL_TRACK_TYPES = [
  SIDING,
  LOCAL,
  NATIONWIDE_PLUS_REGIONAL,
  NATIONWIDE,
  REGIONAL,
  SIDING_PLUS_PERIMETER,
  STATION,
] as const

export type TrackType = typeof ALL_TRACK_TYPES[number]
