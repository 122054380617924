import {FormHelperText as MuiFormHelperText} from '@mui/material'
import type {ComponentProps, FC} from 'react'


declare module '@mui/material/FormHelperText' {
  interface FormHelperTextPropsVariantOverrides {
    primary: true;
    secondary: true;
    success: true;
    info: true;
    warning: true;
    error: true;
    text: true;
  }
}

type FormHelperTextProps = ComponentProps<typeof MuiFormHelperText>

const FormHelperText: FC<FormHelperTextProps> = ({variant, children, ...props}) => {
  return (
    <MuiFormHelperText variant={variant} {...props}>
      {children}
    </MuiFormHelperText>
  )
}

export default FormHelperText
