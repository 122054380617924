import {fetchEventSource} from '@microsoft/fetch-event-source'
import type {FetchEventSourceInit} from '@microsoft/fetch-event-source/lib/cjs/fetch'
import {EventStreamContentType} from '@microsoft/fetch-event-source/lib/cjs/fetch'
import {onlineManager} from '@tanstack/react-query'
import type {ErrorResponse} from 'common/responses'
import type {JSONValue} from 'common/schemas'
import * as routes from 'constants/routes'
import {omitBy, pickBy, isUndefined, mapValues, isEmpty, isObject} from 'lodash-es'
import config from '../../config'
import {SERVER_UNAVAILABLE_ERROR} from '../constants/frontendErrorCodes'
import type {Params} from './generatePath'
import generatePath from './generatePath'
import parseContentDisposition from './parseContentDisposition'


export type Options = {
  data?: Record<string, unknown> | Record<string, unknown>[],
  query?: Record<string, JSONValue>,
  params?: Params,
  sessionToken?: string,
  signal?: AbortSignal,
  asBlob?: boolean,
  asMultipart?: boolean,
}

export type HttpMethod = 'DELETE' | 'GET' | 'POST' | 'PUT'

export const getQueryString = (query?: Options['query']) => {
  const searchParams = omitBy(mapValues(query, JSON.stringify), isUndefined)
  return !isEmpty(searchParams) ? `?${new window.URLSearchParams(searchParams).toString()}` : ''
}

export class FrontendError extends Error {
  data: Partial<ErrorResponse>
}

export const isFrontendError = (error: unknown): error is FrontendError => {
  return isObject(error) && 'data' in error
}

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

const waitForConnection = async (maxAttempts = Infinity) => {
  onlineManager.setOnline(false)
  onlineManager.onOnline()
  let attempt = 0
  while (attempt < maxAttempts) {
    try {
      const res = await window.fetch(config.apiUrl + routes.API + routes.API_HEALTH_CHECK)
      if (!res.ok) throw new Error('Server unavailable')
      // Reset to use current state
      onlineManager.setOnline(undefined)
      onlineManager.onOnline()
      break
    } catch (_e) {
      await sleep(Math.max(2 ** attempt * 1000, 10_000))
      attempt += 1
    }
  }
  const error = new FrontendError('Server unavailable')
  error.data = {errorCode: SERVER_UNAVAILABLE_ERROR}
  return error
}

export const api = async <R, >(method: HttpMethod, pathPattern: string, options: Options):
  Promise<{data: R, filename?: string, total: number | null}> => {
  const {data, query, params, sessionToken, signal, asBlob, asMultipart} = options || {}
  const path = generatePath(pathPattern, params)
  const search = getQueryString(query)
  const hasBody = !['HEAD', 'GET'].includes(method.toUpperCase())
  const headers = pickBy({
    'Content-type': hasBody && !asMultipart ? 'application/json' : undefined,
    'Authorization': sessionToken ? `Bearer ${sessionToken}` : undefined,
  }) as HeadersInit

  const body = (hasBody ? (asMultipart ? data : JSON.stringify(data || {})) : undefined) as BodyInit
  return window.fetch(config.apiUrl + routes.API + path + search, {
    method,
    headers,
    body,
    signal,
    credentials: 'include',
  })
    .catch(async (e) => {
      if (signal && signal.aborted) throw e
      throw await waitForConnection()
    })
    .then(async (res) => {
      // If used behind proxy, may return other errors in case of server unavailability
      if (!res.ok && res.status >= 502 && res.status <= 504) {
        throw await waitForConnection()
      }
      return res
    })
    .then(async (res) => {
      const contentType = res.headers.get('content-type') || ''
      if (contentType.indexOf('application/json') > -1) {
        return res.json().then((data: unknown) => ({res, data}))
      } else if (asBlob) {
        const {filename} = parseContentDisposition(res.headers.get('content-disposition') || '')
        return res.blob().then((data) => ({res, filename, data}))
      } else {
        return res.text().then((data) => ({res, data}))
      }
    })
    .then(({res, filename, data}: {res: Response, filename?: string, data: unknown}) => {
      if (res.ok) {
        const contentRange = res.headers.get('content-range')
        const total = contentRange ? parseInt(String(contentRange.split('/').pop()), 10) : null

        return {data, filename, total} as {data: R, filename?: string, total: number | null}
      } else {
        const errorData = data as ErrorResponse
        const error = new FrontendError(errorData && errorData.message || res.statusText || 'Unknown error')
        error.data = errorData
        throw error
      }
    })
}

export type SSEOptions = FetchEventSourceInit & {
  data?: Record<string, unknown>,
  query?: Record<string, JSONValue>,
  params?: Params,
  sessionToken?: string,
  signal?: AbortSignal,
}
export const sse = async (method: string, pathPattern: string, options: SSEOptions) => {
  const {headers, data, query, params, sessionToken, signal, ...rest} = options || {}
  const path = generatePath(pathPattern, params)
  const search = getQueryString(query)
  const hasBody = !['HEAD', 'GET'].includes(method.toUpperCase())
  const usedHeaders = pickBy({
    'Accept': EventStreamContentType,
    'Content-type': hasBody ? 'application/json' : undefined,
    'Authorization': sessionToken ? `Bearer ${sessionToken}` : undefined,
    ...headers,
  }) as Record<string, string>
  const body = (hasBody ? JSON.stringify(data || {}) : undefined) as BodyInit
  return fetchEventSource(config.apiUrl + routes.API + path + search, {
    method,
    headers: usedHeaders,
    signal,
    body,
    credentials: 'include',
    ...rest,
  })
}
