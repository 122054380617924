import ClearIcon from '@mui/icons-material/Clear'
import DoneIcon from '@mui/icons-material/Done'
import {Choose, When, Otherwise} from 'babel-plugin-jsx-control-statements'
import type {ComponentProps, FC} from 'react'
import type {UseFieldConfig} from 'react-final-form'
import {useField} from 'react-final-form'
import {StaticField, CheckboxInput as VisualCheckboxInput} from '../visual'
import type {ShowError} from './forms'
import {showErrorOnChange} from './forms'


type CheckboxInputProps = ComponentProps<typeof VisualCheckboxInput> & {
  name: string
  label?: string
  required?: boolean
  disableSpacing?: boolean,
  helperText?: string
  showError?: ShowError
  fieldProps?: UseFieldConfig<string, string>,
}

const CheckboxInput: FC<CheckboxInputProps> = ({
  disableSpacing, readOnly, name, label, required, disabled, helperText,
  showError = showErrorOnChange, fieldProps, ...props
}) => {
  const {input: {value, onChange, checked, ...restInput}, meta} = useField(name, {...fieldProps, type: 'checkbox'})
  const {isError, helperTextOrError} = showError({meta, helperText})

  return (
    <Choose>
      <When condition={!readOnly}>
        <VisualCheckboxInput
            name={name}
            label={label}
            value={value}
            onChange={onChange}
            checked={checked}
            helperText={helperTextOrError}
            error={isError}
            required={required}
            disabled={disabled}
            inputProps={{required, ...restInput}}
            {...props}
        />
      </When>
      <Otherwise>
        <StaticField label={label} disableSpacing={disableSpacing}>
          <Choose>
            <When condition={checked}>
              <DoneIcon color="primary" />
            </When>
            <Otherwise>
              <ClearIcon color="error" />
            </Otherwise>
          </Choose>
        </StaticField>
      </Otherwise>
    </Choose>
  )
}

export default CheckboxInput
