import type {ListLicenseResponseSchema} from 'common/responses'
import {differenceInYears, isAfter} from 'date-fns'
import type {ValidationErrors} from 'final-form'
import {ARRAY_ERROR} from 'final-form'
import {isNil} from 'lodash'
import {get, isArray, set} from 'lodash-es'
import type {ReactNode} from 'react'
import type {FieldMetaState} from 'react-final-form'
import type {ZodIssue, ZodType, TypeOf} from 'zod'
import {z, ZodParsedType, ZodIssueCode} from 'zod'
import palette from '../../theme/palette'
import zodErrorMap from './zodErrorMap'


export type ShowError = (args: {meta: FieldMetaState<unknown>, helperText: ReactNode}) => {
  isError: boolean, helperTextOrError: ReactNode
}

export const showErrorOnChange: ShowError = ({
  meta: {submitError, dirtySinceLastSubmit, error, touched, modified},
  helperText,
}) => {
  const isError = Boolean(((submitError && !dirtySinceLastSubmit) || error) && (touched || modified))
  const helperTextOrError = isError ? (error || submitError) as ReactNode : helperText

  return {isError, helperTextOrError}
}

export const showErrorOnBlur: ShowError = ({
  meta: {submitError, dirtySinceLastSubmit, error, touched},
  helperText,
}) => {
  const isError = Boolean(((submitError && !dirtySinceLastSubmit) || error) && touched)
  const helperTextOrError = isError ? (error || submitError) as ReactNode : helperText

  return {isError, helperTextOrError}
}

z.setErrorMap(zodErrorMap)

const parseErrorSchema = (zodErrors: ZodIssue[]) => {
  const errors: ValidationErrors = {}
  for (; zodErrors.length;) {
    const [error] = zodErrors

    if (
      !get(errors, error.path) &&
      !(error.code === ZodIssueCode.invalid_type && error.expected === ZodParsedType.undefined)
    ) {
      if (error.code === ZodIssueCode.invalid_type && error.expected === ZodParsedType.array) {
        // Empty array must be kept as array for final form to work
        if (!isArray(get(errors, error.path))) set(errors, error.path, [])
        error.path.push(ARRAY_ERROR)
      }

      if ('unionErrors' in error) {
        const [unionError] = error.unionErrors[0].errors
        set(errors, error.path, unionError.message)
      } else {
        set(errors, error.path, error.message)
      }
    }

    if ('unionErrors' in error) {
      error.unionErrors.forEach((unionError) => unionError.errors.forEach((e) => zodErrors.push(e)))
    }

    zodErrors.shift()
  }

  return errors
}

type Values = Record<string, unknown>

export const validator = <TSchema extends ZodType<Values | Values[]>>(schema: TSchema, opts = {}) => (
  (passedValues: unknown):
      {errors: undefined, values: TypeOf<TSchema>} | {errors: ValidationErrors, values: undefined} => {
    const res = schema.safeParse(passedValues, opts)

    if (res.success) {
      return {errors: undefined, values: res.data}
    }

    const parsedErrors = parseErrorSchema(res.error.issues)
    return {errors: parsedErrors, values: undefined}
  }
)

export const isLicenseValid = (license: ListLicenseResponseSchema[number]) => {
  const today = new Date()
  const {validFrom, cancelledAt} = license
  return !validFrom || (cancelledAt && isAfter(today, new Date(cancelledAt)))
}

export const supervisionColorMap = {
  valid: palette.success.main,
  warning: palette.warning.main,
  invalid: palette.error.main,
  verification: palette.primary.main,
}

export const getSupervisionColor = (supervisedAt: Date) => {
  if (!supervisedAt) return null
  const difference = differenceInYears(new Date(), supervisedAt)
  if (difference < 3) {
    return supervisionColorMap.valid
  }
  if (difference > 5) {
    return supervisionColorMap.invalid
  }

  return supervisionColorMap.warning
}

export const formatPressure = (value: number | undefined) => isNil(value) ? value : [String(value).slice(0, -3) || 0, String(value).slice(-3)].join(',')
