import {Backdrop, CircularProgress, Stack} from '@mui/material'
import type {FC, ReactNode} from 'react'


type SplashScreenProps = {
  children?: ReactNode
}

const SplashScreen: FC<SplashScreenProps> = ({children}) => (
  <Backdrop open>
    <Stack useFlexGap gap={2} alignItems="center">
      {children}
      <CircularProgress color="inherit" />
    </Stack>
  </Backdrop>
)

export default SplashScreen
