// Config values provided by Webpack DefinePlugin
declare const config: {
  server: {
    url: string,
    passwordMinLength: number,
  }
  sentry: string
}

declare const BUILD_ID: string


export default {
  apiUrl: config.server.url,
  passwordMinLength: config.server.passwordMinLength,
  sentry: config.sentry,
  buildId: BUILD_ID,
} as const
