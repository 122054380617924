import {createTheme} from '@mui/material/styles'
import {csCZ} from '@mui/x-date-pickers/locales'
import overrides from './overrides'
import palette from './palette'


const theme = createTheme({
  components: overrides,
  palette,
  mixins: {
    toolbar: {
      height: '57px',
    },
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
}, csCZ)

export default theme
