import type {ZodErrorMap} from 'zod'
import {ZodIssueCode, ZodParsedType} from 'zod'


export const dateErrorMap: ZodErrorMap = (issue) => {
  let message = 'Neplatné datum'

  if (issue.code === ZodIssueCode.invalid_type && issue.received === ZodParsedType.null) {
    message = 'Povinné'
  }

  return {message}
}
