import {FormControl, FormControlLabel, FormHelperText, Checkbox as MuiCheckbox} from '@mui/material'
import type {ComponentProps, FC, ReactNode} from 'react'


type CheckboxProps = ComponentProps<typeof MuiCheckbox> & {
  label?: string
  helperText?: ReactNode,
  fullWidth?: boolean,
  error?: boolean,
  required?: boolean,
  checked?: boolean,
  formControlLabelProps?: Omit<ComponentProps<typeof FormControlLabel>, 'control' | 'label'>
}

const Checkbox: FC<CheckboxProps> = ({
  label, onChange, helperText, fullWidth, error, required, checked, formControlLabelProps, ...props
}) => {
  return (
    <FormControl
        fullWidth={fullWidth}
        error={error}
        required={required}
    >
      <FormControlLabel
          label={label ? label : null}
          control={
            <MuiCheckbox
                onChange={onChange}
                checked={checked}
                value={checked}
                {...props}
            />
          }
          {...formControlLabelProps}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default Checkbox
