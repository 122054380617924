import {styled} from '@mui/material/styles'
import type {ComponentProps, FC, ReactNode} from 'react'
import FormHelperText from '../FormHelperText/FormHelperText'


const StyledStaticField = styled('div')<StaticFieldProps>(({theme, disableSpacing}) => ({
  width: '100%',
  ...(!disableSpacing && {
    position: 'relative',
    padding: `${theme.spacing(2.5)} 0`,
  }),
}))

const StyledLabel = styled('span')(({theme}) => ({
  position: 'absolute',
  top: '0',
  fontSize: '12px',
  color: theme.palette.text.secondary,

  [theme.breakpoints.down('sm')]: {
    fontSize: '0.65rem',
  },
}))

type StaticFieldProps = {
  disableSpacing?: boolean,
  label?: ReactNode,
  helperText?: ReactNode,
  helperTextProps?: ComponentProps<typeof FormHelperText>,
  children: ReactNode,
}

const StaticField: FC<StaticFieldProps> = ({
  disableSpacing, label, helperText, helperTextProps, children, ...props
}) => {
  return (
    <StyledStaticField disableSpacing={disableSpacing} {...props}>
      <StyledLabel>{label}</StyledLabel>
      {children}
      <FormHelperText {...helperTextProps}>{helperText}</FormHelperText>
    </StyledStaticField>
  )
}

export default StaticField
