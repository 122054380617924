import type {JSONValue} from 'common/schemas'


export const get = <T>(storage: Storage, key: string) => {
  const val = storage.getItem(key)
  if (val === null) return undefined
  try {
    return JSON.parse(val) as T
  } catch (e) {
    storage.removeItem(key) // Prevent permanently broken app
    throw e // But still throw as this is unhandled exception
  }
}

export const set = (storage: Storage, key: string, value: JSONValue) => {
  if (!value) return storage.removeItem(key)
  return storage.setItem(key, JSON.stringify(value))
}
